import React, { useEffect, useRef } from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
// Create an array of refs
const refs = useRef([]);

// Define options for the observer
const options = {
  root: null, // using the viewport
  rootMargin: '0px',
  threshold: [0, 0.23, 1],
};

useEffect(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log(entry);
      if (entry.target.className.includes('hidden-left') && entry.isIntersecting && entry.intersectionRatio > 0.23
      || entry.target.className.includes('hidden-right') && entry.isIntersecting && entry.intersectionRatio > 0.23
      || entry.target.className.includes('hidden-center') && entry.isIntersecting ) {
        entry.target.classList.add('show');
      } else if (!entry.isIntersecting || entry.intersectionRatio < 0.2) {
        entry.target.classList.remove('show');
      }
    });
  }, options); // Pass the options here

  // Set up observation for all elements in the refs array
  refs.current.forEach(el => {
    if (el) observer.observe(el);
  });

  // Cleanup function to unobserve all elements
  return () => {
    refs.current.forEach(el => {
      if (el) observer.unobserve(el);
    });
  };
}, []); // useEffect should only re-run if the items in this dependency array change

// Function to add elements to the refs array
const addToRefs = el => {
  if (el && !refs.current.includes(el)) {
    refs.current.push(el);
  }
};

  return (
    <div className='cards'>
      <h1> Check out these EPIC Destinatios!</h1>
      <div className='cards-container'>
        <div className='cards-wrapper'>
            <ul className='cards-items'>
              <div ref={addToRefs} className='cards-item hidden-left'>
                <CardItem 
                    src='images/img-9.jpg'
                    text='Explore the hidden waterfall deep inside the Amazon Jungle'
                    lable='Adventures'
                    path='/services'
                />
              </div>
              <div ref={addToRefs} className='cards-item hidden-right'>
                <CardItem 
                    src='images/img-2.jpg'
                    text='Travel through the Islands of Bali in a Private Cruise'
                    lable='Luxury'
                    path='/services'
                />
              </div>
            </ul>
            <ul className='cards-items'>
              <div ref={addToRefs} className='cards-item hidden-left'>
                <CardItem 
                    src='images/img-9.jpg'
                    text='Explore the hidden waterfall deep inside the Amazon Jungle'
                    lable='Adventures'
                    path='/services'
                />
              </div>
              <div ref={addToRefs} className='cards-item hidden-center'>
                <CardItem 
                    src='images/img-2.jpg'
                    text='Travel through the Islands of Bali in a Private Cruise'
                    lable='Luxury'
                    path='/services'
                />
              </div>
              <div ref={addToRefs} className='cards-item hidden-right'>
                <CardItem 
                    src='images/img-2.jpg'
                    text='Travel through the Islands of Bali in a Private Cruise'
                    lable='Luxury'
                    path='/services'
                />
              </div>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards
