import React from "react";
import '../../App.css';
import Footer from '../Footer';
import '../Button.css';
import {Link} from 'react-router-dom';

function LogIn() {
    return(
        <div>
            <div className='login'>
                <form action="">
                <h1>Log In</h1>
                    <div>
                        <h2 htmlFor="email"><strong>Email</strong></h2>
                        <input type="email" placeholder='Enter Email'/>
                    </div>
                    <div>
                        <h2 htmlFor="password"><strong>Password</strong></h2>
                        <input type="password" placeholder='Enter Password'/>
                    </div>
                    <button className='btn btn--primary btn--outline btn--medium'><strong>Log in</strong></button>
                    <p></p>
                    <Link to='/Sign-Up'>
                        <button className='btn btn--primary btn--outline btn--medium'><strong>Register</strong></button>
                    </Link>
                </form>
            </div>
            <Footer />
        </div>

    ) 
}

export default LogIn