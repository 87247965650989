import React from "react";
import '../../App.css';
import Footer from '../Footer';
import '../Button.css';
import {Link} from 'react-router-dom';

function SignUp() {
    return(
        <div>
            <div className='login'>
                <form action="">
                <h1>Sign Up</h1>
                    <div>
                        <h2 htmlFor="name"><strong>Name</strong></h2>
                        <input type="text" placeholder='Enter Name'/>
                    </div>
                    <div>
                        <h2 htmlFor="surname"><strong>Surname</strong></h2>
                        <input type="text" placeholder='Enter Surname'/>
                    </div>
                    <div>
                        <h2 htmlFor="email"><strong>Email</strong></h2>
                        <input type="email" placeholder='Enter Email'/>
                    </div>
                    <div>
                        <h2 htmlFor="password"><strong>Password</strong></h2>
                        <input type="password" placeholder='Enter Password'/>
                    </div>
                    <div>
                        <h2 htmlFor="password"><strong>Confirm Password</strong></h2>
                        <input type="password" placeholder='Confirm Password'/>
                    </div>
                    <div>
                        <input id="newscb" type="checkbox" defaultChecked={true}></input> 
                        <label htmlFor="newsletter"><strong>Sign up to our newsletter</strong></label>
                    </div>
                    <div>
                        <input id="termscb" type="checkbox"></input> 
                        <label htmlFor="terms"><strong>Accept our terms and conditions</strong></label>
                    </div>
                    <button className='btn btn--primary btn--outline btn--medium'><strong>Sign Up</strong></button>
                    <p></p>
                    <Link to='/Log-In'>
                        <button className='btn btn--primary btn--outline btn--medium'><strong>Log In</strong></button>
                    </Link>
                </form>
            </div>
            <Footer />
        </div>

    ) 
}

export default SignUp